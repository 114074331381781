export const PageAnimation = {
  hidden: {
    opacity: 0,
    y: 300,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.25,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.4,
    },
  },
};

export const slideUp = {
  hidden: {
    y: 200,
  },
  show: {
    y: 0,
    transition: {
      duration: 0.75,
      ease: "easeOut",
    },
  },
};

export const fadeIn = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      // staggerChildren: 0.2,
      ease: "easeOut",
      duration: 1,
    },
  },
};

export const zoomOut = {
  hidden: {
    scale: 1.5,
    opacity: 0,
  },
  show: {
    scale: 1,
    opacity: 1,
    transition: {
      ease: "easeOut",
      duration: 0.75,
    },
  },
};

export const slideRight = {
  hidden: {
    x: "-100%",
  },
  show: {
    x: 0,
    transition: {
      duration: 1,
      ease: "easeOut",
    },
  },
};

export const rotateContainer = {
  hidden: {
    opacity: 1,
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      // duration: 1,
      ease: "easeOut",
    },
  },
};

export const rotate = {
  hidden: {
    x: "-130%",
    skew: "45deg",
  },
  show: {
    x: "100%",
    skew: "0deg",
    transition: {
      duration: 1,
      ease: "easeOut",
    },
  },
};

export const scrollReveal = {
  hidden: {
    opacity: 0,
    scale: 1.2,
    transition: {
      // ease: "easeOut",
      duration: 0.5,
    },
  },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      // ease: "easeOut",
      duration: 0.5,
    },
  },
};

export const popUp = {
  hidden: { scale: 0 },
  show: {
    scale: 1,
    // transform: "translateX(50%)",
    transition: {
      duration: 1,
      ease: "easeOut",
    },
  },
};
